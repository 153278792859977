




















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiRefresh, mdiClose, mdiMagnify } from '@mdi/js';
import { getThingList, removeThing } from '../../api/admin';
import { DeviceType } from '../../typings';
import type { Moment } from 'moment';
import { noop } from 'vue-class-component/lib/util';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '../../plugins/store';
import { Hub } from '@aws-amplify/core';
import { ADMIN_USER_GROUP } from '../../constants';
import Auth from '@aws-amplify/auth';

const settingsStore: SettingsModule = getModule(SettingsModule);

interface ListItem {
  text: string;
  subtitle: string;
  date: Moment;
  link: string;
}

@Component
export default class ShadowListView extends Vue {
  private readonly ADMIN_USER_GROUP: typeof ADMIN_USER_GROUP =
    ADMIN_USER_GROUP;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiClose: string = mdiClose;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly noop: typeof noop = noop;

  private loading: boolean = true;
  private removeUid: string = '';
  private items: ListItem[] = [];
  private userGroups: string[] = [];

  private get showConsole(): boolean {
    return settingsStore.console;
  }

  private get filteredItems(): ListItem[] {
    return this.items.filter(
      (item: ListItem): boolean =>
        !this.searchList ||
        JSON.stringify(item)
          .toUpperCase()
          .includes(this.searchList.toUpperCase())
    );
  }

  @Prop({ type: String, default: '' })
  private readonly searchList!: string;

  private async created(): Promise<void> {
    this.userGroups =
      (
        await Auth.currentSession().catch((): undefined => undefined)
      )?.getIdToken()?.payload?.['cognito:groups'] || [];
  }

  private mounted(): void {
    this.getThingList();
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getThingList(): void {
    this.loading = true;
    getThingList()
      .then(
        (items: string[]): void =>
          void (this.items = items
            .map(
              (item: string): ListItem => ({
                text: item,
                subtitle: `$vuetify.shadows.${
                  item.startsWith('G-')
                    ? DeviceType.GATEWAY.toUpperCase()
                    : item.startsWith('I-')
                    ? DeviceType.INTERFACE.toUpperCase()
                    : DeviceType.OTHER.toUpperCase()
                }`,
                date: this.$moment(item.substring(2, 10), 'DDMMYYYY'),
                link: `/shadows/${item}`
              })
            )
            .sort((a: ListItem, b: ListItem): number =>
              b.date.isSame(a.date)
                ? parseInt(b.text.substring(11), 10) -
                  parseInt(a.text.substring(11), 10)
                : b.date.diff(a.date)
            ))
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private removeThing(): void {
    if (!this.removeUid) {
      return;
    }
    const uid: string = this.removeUid;
    this.loading = true;
    this.removeUid = '';
    removeThing(uid)
      .then((): void => {
        this.getThingList();
        this.$router.replace('/shadows').catch(noop);
      })
      .catch((error: Error): void => {
        this.loading = false;
        this.showError(error.message);
      });
  }
}
